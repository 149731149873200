<template>
    <layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Create Trainer</h4>
                        <form @submit.prevent="createTrainee" ref="form">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Name and Surname</label>
                                        <input
                                                type="text"
                                                placeholder="Name"
                                                class="form-control"
                                                v-model="name_and_surname"
                                        />

                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label > Region </label>
                                        <multiselect v-model="region" label="name" :options="regionData" :multiple="false" placeholder="Select Region"></multiselect>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Site</label>
                                        <multiselect v-model="institution" label="site_name" :options="siteData" :multiple="false" placeholder="Select Site"></multiselect>
                                        <!-- <input
                                                type="text"
                                                v-model="institution"
                                                placeholder="Site"
                                                class="form-control"
                                        /> -->

                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label > S/N</label>
                                        <input
                                                type="text"
                                                v-model="sn"
                                                placeholder="S/N "
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label > Responsibility</label>
                                        <input
                                                type="text"
                                                placeholder="Responsibility "
                                                class="form-control"
                                                v-model="responsibility"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label > Phone</label>
                                        <input
                                                v-model="phone"
                                                type="number"
                                                placeholder="Phone "
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label > Email</label>
                                        <input
                                                type="email"
                                                v-model="email"
                                                placeholder="Email "
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label > Date of training</label>
                                        <date-picker v-model="date_of_training" range append-to-body lang="en" placeholder="Date of training" confirm></date-picker>
                                    </div>
                                </div>


                            </div>
                            <button class="btn btn-primary" type="submit">Create</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="lodader__content" v-if="loader">
            <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
        </div>
    </layout>
</template>

<script>
    import Layout from "../../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Multiselect from "vue-multiselect";
    import DatePicker from "vue2-datepicker";
    import {axios_get, axios_post} from "../../../../helpers/helper";

    export default {
        page: {
            title: "Create Trainee",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout,Multiselect,PageHeader,DatePicker },
        data() {
            return {
                title: "Create Trainee",
                loader: false,
                items: [
                    {
                        text: "Dashboard",
                        href: "/"
                    },
                    {
                        text: "Create trainee",
                        active: true
                    }
                ],
                options1: [
                    "Assistant lab Tech ",
                    "QA lead",
                    "Focal Point",
                    "Other ",
                ],
                date_of_training:"",


                sn:"",
                name_and_surname:"",
                region:"",
                responsibility:"",
                institution:"",
                refresher_date:"",
                siteData: [],
                phone:"",
                email:"",
                value1: null,
                regionData:[],
                date_of:"",
            };

        },
        validations: {

        },
        mounted() {
            this.countries=JSON.parse(localStorage.getItem('user'))
            console.log(this.countries.country_id)
            this.country_id=this.countries.country_id
            axios_get("/regions",{},(d)=>{
                    console.log(d)
                    this.regionData=d
                    this.regionData=this.regionData.filter(e=>{return e.country_id==this.country_id})
                    // localStorage.setItem('regions',JSON.stringify(this.regionData))
                    console.log(this.regionData)
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                }
            );
            axios_get("/sites",{},(d)=>{
                    console.log(d)
                    //this.countryData=d;
                    this.siteData=d.filter(e=>{return e.region.country_id == this.countries.country_id})
                    this.siteData=this.siteData.map(e=>{e.region = e.region.name; return e})
                        console.log(this.siteData)
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                }
            );
        },
        methods: {
            createTrainee(){
                this.loader = true
                this.submitted = true;
                // stop here if form is invalid
                this.$v.$touch();
                let dateinfo =this.date_of_training[0].toLocaleString();
                let dateinfo1 =this.date_of_training[1].toLocaleString();
                console.log(dateinfo)
                console.log(dateinfo1)
                let splitdata = dateinfo.split(" ");
                console.log(splitdata[0])
                let splitdata1 = dateinfo1.split(" ");
                console.log(splitdata1[0])
                this.date_of = splitdata[0] +" - "+splitdata1[0];
                console.log(this.date_of)
                axios_post("/trainee",{
                    sn:this.sn,
                    name_and_surname:this.name_and_surname,
                    region_id:this.region.id,
                    responsibility:this.responsibility,
                    site_id:this.institution.id,
                    date_of_training:this.date_of,
                    phone:this.phone,
                    email:this.email,
                },(d)=>{
                    window.location.href='/training/view-trainees',
                    console.log(d);
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                    this.loader = false
                })

            },
            resetForm(){
                this.$refs['form'].reset()
            },

        }
    };
</script>

<style scoped>
    .lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }
</style>